import React from "react";
import SEO from "@src/components/SEO";
import queryString from "query-string";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Schoolwide = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { interest, make_official, stage } = parsedLocation;

  useRedirectHandler(() => {
    if (interest && stage) {
      return `https://teach.classdojo.com/#/classdojoSchoolSignup?interest=true&stage=${stage}`;
    } else if (interest) {
      return `https://teach.classdojo.com/#/classdojoSchoolSignup?interest=true`;
    } else if (stage) {
      return `https://teach.classdojo.com/#/classdojoSchoolSignup?stage=${stage}`;
    } else if (make_official) {
      return `https://teach.classdojo.com/#/classdojoSchoolSignup?make_official=true`;
    } else {
      return `https://teach.classdojo.com/#/classdojoSchoolSignup`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Schoolwide;
